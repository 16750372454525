<template>
  <form
    v-on:submit.prevent="submit"
    enctype="multipart/form-data"
    class="form-row"
    id="form"
  >
    <FlashMessage></FlashMessage>
    <div class="banner mb-3">
      <img
        :src="$root.baseurl + '/public/assets/frontend/images/form.jpg'"
        style="width: 100%; height: 100%"
      />
    </div>
    <div
      v-if="errors && Object.keys(errors).length > 0"
      style="min-height: 20px; background: rgb(255 220 220)"
    >
      <div class="error pl-3 pr-4 p-1 text-center">
        <span
          v-for="(err, errIndex) in errors"
          :key="errIndex"
          class="text-danger"
        >
          {{ err[0] }} <br />
        </span>
      </div>
    </div>
    <!-- COMPANY INFORMATION -->
    <fieldset class="col-12 row ml-0 border border-info w-100">
      <legend class="w-50 pl-2 text-center">COMPANY INFORMATION</legend>
      <div class="form-group col-sm-12">
        <label for="" class="col-form-label-sm m-0 req"
          >Company Name:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.company_name") }}
          </span>
        </label>

        <model-list-select
          v-if="members && Object.keys(members).length > 0"
          :list="members"
          v-model="data.company_name"
          option-value="company_name"
          option-text="company_name"
          name="company[company_name]"
          placeholder="Select Company"
        ></model-list-select>
        <div v-else style="color: #1d5903">
          <i class="fa fa-circle-o-notch fa-spin"></i> please wait...
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label for="" class="col-form-label-sm m-0 req"
          >Membership ID:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.membership_id") }}
          </span>
        </label>
        <input
          :class="{
            'has-error': validation.hasError('data.membership_id'),
            'has-success': data.membership_id,
          }"
          type="text"
          readonly
          v-model="data.membership_id"
          name="company[membership_id]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0 req"
          >Year of Establishment:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.date") }}
          </span></label
        >
        <input
          :class="{
            'has-error': validation.hasError('data.date'),
            'has-success': data.date,
          }"
          type="text"
          readonly
          v-model="data.date"
          name="company[date]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="" class="col-form-label-sm m-0"
          >Website Link (ex: http:// or https://):
          <span class="text-danger error-msg">
            {{ validation.firstError("data.website") }}
          </span>
        </label>
        <input
          type="url"
          v-model="data.website"
          name="company[website]"
          class="form-control shadow-none"
        />
        <input
          type="hidden"
          v-model="data.company_profile"
          name="company[company_profile]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0 req"
          >Company Logo:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.logo") }}
          </span></label
        >
        <b-form-file
          :class="{
            'has-error': validation.hasError('data.logo'),
            'has-success': data.logo,
          }"
          accept="image/*"
          id="file-small"
          size="sm"
          name="logo"
          v-model="data.logo"
          drop-placeholder="Drop file here"
        ></b-form-file>
      </div>
    </fieldset>

    <!-- CONTACT PERSON DETAILS -->
    <fieldset class="col-12 row ml-0 border border-info w-100 mt-5">
      <legend class="w-50 pl-2 text-center">CONTACT PERSON INFORMATION</legend>

      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Contact Person Name:</label
        >
        <input
          type="text"
          v-model="data.contact_name"
          name="contact[contact_name]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0">Designation:</label>
        <input
          type="text"
          v-model="data.contact_designation"
          name="contact[contact_designation]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Email Address:</label
        >
        <input
          type="email"
          v-model="data.contact_email"
          name="contact[contact_email]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0">Mobile:</label>
        <input
          type="text"
          v-model="data.contact_mobile"
          name="contact[contact_mobile]"
          class="form-control shadow-none"
        />
      </div>
    </fieldset>

    <!-- PRODUCT DETAILS -->
    <fieldset class="col-12 row ml-0 border border-info w-100 mt-5">
      <legend class="w-50 pl-2 text-center">PRODUCT INFORMATION</legend>
      <div class="form-group col-sm-12">
        <label for="" class="col-form-label-sm m-0 req"
          >Product Category:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.logo") }}
          </span>
        </label>
        <select
          :class="{
            'has-error': validation.hasError('data.product_category_id'),
            'has-success': data.product_category_id,
          }"
          v-model="data.product_category_id"
          name="product[product_category_id]"
          class="form-control shadow-none"
        >
          <option value="">--Select One--</option>
          <slot v-if="categories">
            <option v-for="(name, id) in categories" :value="id" :key="id">
              {{ name }}
            </option>
          </slot>
        </select>
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0 req"
          >Product Name:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.logo") }}
          </span></label
        >
        <input
          :class="{
            'has-error': validation.hasError('data.product_name'),
            'has-success': data.product_name,
          }"
          type="text"
          v-model="data.product_name"
          name="product[product_name]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Number of Clients:</label
        >
        <input
          type="number"
          v-model="data.clients"
          name="product[clients]"
          class="form-control shadow-none"
        />
      </div>
      <div class="form-group col-sm-12">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Name of the Clients of this products:</label
        >
        <div
          v-for="(client, index) in data.client_name"
          :key="index"
          class="row mb-2"
        >
          <div class="col-10">
            <input
              type="text"
              v-model="client.name"
              :name="'product[client_name][' + index + '][name]'"
              placeholder="Client name"
              class="form-control shadow-none"
            />
          </div>
          <div class="col-1" v-if="index > 0">
            <button
              @click="data.client_name.splice(index, 1)"
              type="button"
              class="btn btn-sm btn-danger"
            >
              X
            </button>
          </div>
          <div
            class="col-1"
            v-if="index + 1 == Object.keys(data.client_name).length"
          >
            <button
              type="button"
              @click="data.client_name.push({ name: '' })"
              class="btn btn-sm btn-info"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0 req"
          >Product Logo:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.image") }}
          </span></label
        >
        <b-form-file
          :class="{
            'has-error': validation.hasError('data.image'),
            'has-success': data.image,
          }"
          accept="image/*"
          id="file-small"
          size="sm"
          name="image"
          v-model="data.image"
          drop-placeholder="Drop file here"
        ></b-form-file>
      </div>
      <div class="form-group col-sm-6">
        <label for="cell_no" class="col-form-label-sm m-0 req"
          >Product Brochure:
          <span class="text-danger error-msg">
            {{ validation.firstError("data.file") }}
          </span>
        </label>
        <b-form-file
          :class="{
            'has-error': validation.hasError('data.file'),
            'has-success': data.file,
          }"
          accept=".pdf"
          id="file-small"
          size="sm"
          name="file"
          v-model="data.file"
          drop-placeholder="Drop file here"
        ></b-form-file>
      </div>
      <div class="form-group col-sm-12">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Please mention your target your industry:</label
        >
        <textarea
          v-model="data.target_industry"
          name="product[target_industry]"
          class="form-control shadow-none"
        ></textarea>
      </div>
      <div class="form-group col-sm-12">
        <label for="cell_no" class="col-form-label-sm m-0"
          >Product / Service Link:</label
        >
        <input
          type="text"
          v-model="data.product_link"
          name="product[product_link]"
          class="form-control shadow-none"
        />
      </div>
    </fieldset>

    <div class="form-group col-sm-7 text-right mt-5">
      <button
        type="submit"
        :disabled="submitSpinner ? true : false"
        class="btn btn-info pl-5 pr-5"
      >
        <span v-if="submitSpinner">
          <i class="fa fa-spinner fa-spin"></i> processing...
        </span>
        <span v-else>Submit</span>
      </button>
    </div>
    <!-- <pre>
      {{ data }}
    </pre> -->
  </form>
</template>

<script>
import { ModelListSelect } from "vue-search-select";
export default {
  components: {
    ModelListSelect,
  },
  data() {
    return {
      submitSpinner: false,
      members: {},
      categories: {},
      data: {
        date: "",
        website: "",
        membership_id: "",
        company_profile: "",
        product_category_id: "",
        client_name: [{ name: "" }],
      },
      errors: {},
    };
  },
  watch: {
    "data.company_name": function (val, oldVal) {
      axios
        .get("/get-member-info", { params: { company: val } })
        .then((res) => {
          this.data.date = res.data.date;
          this.data.website = res.data.website;
          this.data.membership_id = res.data.membership_id;
          this.data.company_profile =
            "https://basis.org.bd/company-profile/" + res.data.membership_no;
        });
    },
  },
  methods: {
    submit: function () {
      this.$validate().then((res) => {
        const error = this.validation.countErrors();
        // If there is an error
        if (error > 0) {
          this.notification(
            "You need to fill " + error + " more empty mandatory fields",
            "warning"
          );
          console.log(this.validation.allErrors(), this.array_errors);
          return false;
        }

        // If there is no error
        if (res) {
          this.submitSpinner = true;
          this.errors = {};
          var form = document.getElementById("form");
          var formData = new FormData(form);
          formData.append("company[company_name]", this.data.company_name);
          axios
            .post("/product-register", formData)
            .then((res) => {
              this.notification("Submit successfully", "success");
              window.location.href = "/registration-success";
            })
            .catch((error) => {
              this.submitSpinner = false;
              if (error.response.status === 422) {
                window.scrollTo(0, 100);
                this.errors = error.response.data.errors || {};
                return false;
              }
              this.notification(
                "Something went wrong, please try again",
                "warning"
              );
            })
            .then((alw) => setTimeout(() => (this.submitSpinner = false), 200));
        }
      });
    },
  },
  created() {
    axios.get("/get-members").then((res) => (this.members = res.data));
    axios.get("/get-categories").then((res) => (this.categories = res.data));
  },
  validators: {
    "data.company_name": function (value = null) {
      return Validator.value(value).required("is required");
    },
    "data.membership_id": function (value = null) {
      return Validator.value(value).required("is required");
    },
    "data.date": function (value = null) {
      return Validator.value(value).required("is required");
    },
    "data.product_category_id": function (value = null) {
      return Validator.value(value).required("is required");
    },
    "data.product_name": function (value = null) {
      return Validator.value(value).required("is required");
    },
    "data.website": function (value = null) {
      return Validator.value(value).url();
    },
    "data.clients": function (value = null) {
      return Validator.value(value).digit();
    },

    // image and attachment
    "data.logo": function (value = null) {
      return Validator.value(value)
        .required("is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "must be smaller than 2mb";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/png" ||
              type == "image/jpg"
            ) {
            } else {
              return "must be of type .jpg or .png";
            }
          }
        });
    },
    "data.image": function (value = null) {
      return Validator.value(value)
        .required("is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "must be smaller than 2mb";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/png" ||
              type == "image/jpg"
            ) {
            } else {
              return "must be of type .jpg or .png";
            }
          }
        });
    },
    "data.file": function (value = null) {
      return Validator.value(value)
        .required("is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "must be smaller than 2mb";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "must be of type .pdf";
            }
          }
        });
    },
  },
};
</script>

<style >
.apply-form form {
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 20px 0 #a82877;
}

.banner {
  position: relative;
  height: 210px;
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1612566166300-fb3ce57b8f36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=375&q=80");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.apply-form input:hover,
.apply-form select:hover,
.apply-form textarea:hover {
  border: 1px solid transparent;
  box-shadow: 0 0 6px 0 #a82877 !important;
  color: #a82877;
}

.apply-form label {
  font-size: 0.9rem !important;
  color: #555 !important;
}

.apply-form input,
select,
textarea {
  padding: 0.25rem 0.45rem !important;
  border-radius: 0.15rem !important;
}

.apply-form legend {
  font-size: 20px;
  font-weight: 700;
}

.apply-form label.req::after {
  content: "*";
  color: red;
}
.apply-form .error-msg {
  font-size: 14px;
}
.apply-form .has-error {
  border: 1px solid red;
}

._vue-flash-msg-body {
  width: 25% !important;
  z-index: 9999999;
}
._vue-flash-msg-body__content h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
}
._vue-flash-msg-body ._vue-flash-msg-body__icon {
  background-color: #fff0 !important;
}
</style>