require('./frontend_bootstrap');
window.Vue = require('vue');
require('./Helpers/mixin');
require('./Helpers/Frontend/plugin');

Vue.component('ApplyForm', require('./components/frontend/Form.vue').default);
Vue.component('success', require('./components/frontend/Success.vue').default);

// ===============app===============
const app = new Vue({
    el: '#app',
    data: {
        baseurl: laravel.baseurl,
        spinner: false,
    },
    methods: {},
    mounted() { },
});
