import Vue from 'vue';

// ===============Flash Message===============
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);

// ===============Spinner===============
import Spinner from './../../components/elements/Spinner'
Vue.component('Spinner', Spinner)

// ===============Simple Vue Validator===============
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
window.Validator = Validator;
Vue.use(SimpleVueValidation);

// ===============Bootstrap Vue===============
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

import VueSearchSelect from 'vue-search-select/dist/VueSearchSelect.css'
Vue.use(VueSearchSelect);